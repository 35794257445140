import React from "react"
import styled from "styled-components"

const Label = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 5px 0;
  font-family: "Space Grotesk", monospace;
`

const DateLabel = ({ date }) => {
  const dateValue = new Date(date)
  return <Label>{dateValue.toDateString()}</Label>
}

export default DateLabel

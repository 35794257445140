import React from "react"
import { Link, graphql } from "gatsby"
import { Pagination } from "../components/pagination/Pagination"
import styled from "styled-components"
import SEO from "../components/seo"

import Layout from "../components/layout"
import BlogHeader from "../components/BlogHeader"
import DateLabel from "../components/DateLabel"
import { media } from "../components/sky-splash/styles"

const Title = styled.h3`
  font-family: "Outfit", sans-serif;
  margin: 0;
  display: inline-block;
  font-size: 24px;
  a {
    color: #fff;
    text-decoration: none;
    line-height: 1.5;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  }
`
const Excerpt = styled.p`
  font-family: "Space Grotesk", monospace;
  font-size: 18px;
`

const PostIntro = styled.div`
  background: rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  margin-bottom: 36px;
`
export const query = graphql`
  query LabQuery($limit: Int!, $skip: Int!) {
    allPrismicBlog(
      sort: { fields: data___create_date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        uid
        data {
          title {
            text
          }
          content {
            text
          }
          thumbnail {
            url
            dimensions {
              height
              width
            }
          }
          create_date
        }
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

const ThumbnailLayout = styled.div`
  margin-top: 8px;
  grid-gap: 16px;
  display: flex;
  ${media.handheld`
  display: block;
  img {
    float: left;
    margin: 1em;
    height: 100px;
  }
  `}
`

const BlogList = ({ data, location }) => {
  if (!data) return null
  const docs = data.allPrismicBlog
  return (
    <Layout location={location}>
      <SEO title="Blog" />
      <BlogHeader />
      {docs.nodes.map((post, i) => {
        return (
          <PostIntro key={i}>
            <Title>
              <Link to={`/blog/${post.uid}`}>{post.data.title.text}</Link>
            </Title>
            <ThumbnailLayout>
              {post.data.thumbnail.url && <img src={post.data.thumbnail.url} />}
              <div>
                <DateLabel date={post.data.create_date} />
                <Excerpt>{post.data.content.text.slice(0, 250)}...</Excerpt>
              </div>
            </ThumbnailLayout>
          </PostIntro>
        )
      })}
      <Pagination pageInfo={docs.pageInfo} collection="blogs" />
    </Layout>
  )
}

export default BlogList

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {media} from './sky-splash/styles'

const BlogTitle = styled.h1`
  font-family: "Outfit", sans-serif;
  font-size: 36px;
  display: inline-block;
  margin-right: 10px;
  line-height: 1;
  color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
`
const Subtitle = styled.h3`
  font-size: 20px;
  display: inline-block;
  font-family: inherit;
  letter-spacing: 0.75px;
`

const StyledHeader = styled(Link)`
  position: relative;
  color: rgba(255,255,255, 0.6);
  display: block;
  margin-bottom: 5px;
`
const Wrapper = styled.div`
padding: 10px 0;
${media.handheld`
padding: 16px;
`}
`;
const BlogHeader = ({ children, size }) => {
  return (
    <Wrapper>
      <StyledHeader to="/blogs">
        <BlogTitle >Random Grumbles</BlogTitle>
        <Subtitle size={size}>by Frances Ng</Subtitle>
      </StyledHeader>
      {children}
    </Wrapper>
  )
}
export default BlogHeader

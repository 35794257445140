import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledLink = styled(Link)`
  text-decoration: none;
  font-family: 'Outfit',sans-serif;
  font-weight:700;
  padding: 8px 16px;
  border-radius: 50%;
  margin: 4px;
  font-size:20px;
  background: ${({ ["data-active"]: active }) =>
    active ? "#e56355" : "transparent"};
    color: ${({ ["data-active"]: active }) =>
    active ? "#fff" : "#000"};
`
const Wrapper = styled.div`
  margin: 16px 0px;
`
const getPageNumberPath = (collection, currentIndex) => {
  if (currentIndex === 0) {
    return `/${collection}/1`
  }
  return `/${collection}/${currentIndex + 1}`
}

export const Pagination = ({ collection, pageInfo, path }) => {
  if (!pageInfo) return null
  const { currentPage, pageCount } = pageInfo
  return (
    <Wrapper>
      {Array.from({ length: pageCount }, (_, i) => (
        <StyledLink
          data-active={currentPage === i + 1}
          to={getPageNumberPath(collection, i)}
          key={i + 1}
        >
          {i + 1}
        </StyledLink>
      ))}
    </Wrapper>
  )
}
